// action - state management
import {
  GET_LIFE_INSURANCE,
  GET_LONGTERM_CARE_AND_MEDICARE,
  VIEW_LONG_TERM_CARE_AND_MEDICARE,
  VIEW_LIFE_INSURANCE,
  ADD_LI_NOTE,
  UPDATE_LI_NOTE,
  DELETE_LI_NOTE,
  ADD_LTC_NOTE,
  UPDATE_LTC_NOTE,
  DELETE_LTC_NOTE,
  DELETE_PLI_NOTE,
  UPDATE_PLI_NOTE,
  ADD_PLI_NOTE,
  GET_PERMANENT_LIFE_INSURANCE,
  VIEW_PERMANENT_LIFE_INSURANCE
} from './constant';

export const initialState = {
  get_life_insurance: '',
  get_long_term_and_care: '',
  get_permanent_life_insurance: '',
  view_life_insurance: '',
  view_long_term_and_medicare: '',
  view_permanent_life_insurance: ''
};

// ==============================|| CUSTOMIZATION REDUCER ||============================== //

const formSubmissionReducer = (state = initialState, action) => {
  switch (action.type) {
    case GET_LIFE_INSURANCE:
      return {
        ...state,
        get_life_insurance: action.payload
      };
    case GET_LONGTERM_CARE_AND_MEDICARE:
      return {
        ...state,
        get_long_term_and_care: action.payload
      };
    case GET_PERMANENT_LIFE_INSURANCE:
      return {
        ...state,
        get_permanent_life_insurance: action.payload
      };
    case VIEW_PERMANENT_LIFE_INSURANCE:
      return {
        ...state,
        view_permanent_life_insurance: action.payload
      };
    case ADD_PLI_NOTE:
      return {
        ...state,
        view_permanent_life_insurance: {
          ...state.view_permanent_life_insurance,
          life_insurance_permanent_notes: [...(state.view_permanent_life_insurance?.life_insurance_permanent_notes || []), action.payload]
        }
      };
    case UPDATE_PLI_NOTE:
      return {
        ...state,
        view_permanent_life_insurance: {
          ...state.view_permanent_life_insurance,
          life_insurance_permanent_notes: state.view_permanent_life_insurance?.life_insurance_permanent_notes.map((note) =>
            note.id === action.payload.id ? action.payload : note
          )
        }
      };
    case DELETE_PLI_NOTE:
      return {
        ...state,
        view_permanent_life_insurance: {
          ...state.view_permanent_life_insurance,
          life_insurance_permanent_notes: state.view_permanent_life_insurance?.life_insurance_permanent_notes.filter(
            (note) => note.id !== action.payload.id
          )
        }
      };
    case VIEW_LIFE_INSURANCE:
      return {
        ...state,
        view_life_insurance: action.payload
      };
    case ADD_LI_NOTE:
      return {
        ...state,
        view_life_insurance: {
          ...state.view_life_insurance,
          life_insurance_notes: [...(state.view_life_insurance?.life_insurance_notes || []), action.payload]
        }
      };
    case UPDATE_LI_NOTE:
      return {
        ...state,
        view_life_insurance: {
          ...state.view_life_insurance,
          life_insurance_notes: state.view_life_insurance?.life_insurance_notes.map((note) =>
            note.id === action.payload.id ? action.payload : note
          )
        }
      };
    case DELETE_LI_NOTE:
      return {
        ...state,
        view_life_insurance: {
          ...state.view_life_insurance,
          life_insurance_notes: state.view_life_insurance?.life_insurance_notes.filter((note) => note.id !== action.payload.id)
        }
      };

    case VIEW_LONG_TERM_CARE_AND_MEDICARE:
      return {
        ...state,
        view_long_term_and_medicare: action.payload
      };
    case ADD_LTC_NOTE:
      return {
        ...state,
        view_long_term_and_medicare: {
          ...state.view_long_term_and_medicare,
          long_term_notes: [...(state.view_long_term_and_medicare?.long_term_notes || []), action.payload]
        }
      };
    case UPDATE_LTC_NOTE:
      return {
        ...state,
        view_long_term_and_medicare: {
          ...state.view_long_term_and_medicare,
          long_term_notes: state.view_long_term_and_medicare?.long_term_notes.map((note) =>
            note.id === action.payload.id ? action.payload : note
          )
        }
      };
    case DELETE_LTC_NOTE:
      return {
        ...state,
        view_long_term_and_medicare: {
          ...state.view_long_term_and_medicare,
          long_term_notes: state.view_long_term_and_medicare?.long_term_notes.filter((note) => note.id !== action.payload.id)
        }
      };
    default:
      return state;
  }
};

export default formSubmissionReducer;
