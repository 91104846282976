import { useState, useRef, useEffect } from 'react';

import { useNavigate } from 'react-router-dom';
import { useDispatch, useSelector } from 'react-redux';

// material-ui
import { useTheme } from '@mui/material/styles';
import {
  Avatar,
  Box,
  Chip,
  ClickAwayListener,
  Divider,
  List,
  ListItemButton,
  ListItemText,
  Paper,
  Popper,
  Typography
} from '@mui/material';

// third-party
import PerfectScrollbar from 'react-perfect-scrollbar';

// project imports
import MainCard from 'ui-component/cards/MainCard';
import Transitions from 'ui-component/extended/Transitions';
import { ReactComponent as Person } from 'assets/images/icons/Person.svg';
import { logout } from 'store/Authentication/action';

// assets
import { IconCaretDownFilled, IconCaretUpFilled } from '@tabler/icons-react';

// ==============================|| PROFILE MENU ||============================== //

const ProfileSection = () => {
  const theme = useTheme();
  const dispatch = useDispatch();
  const auth = useSelector((state) => state?.auth?.login);
  const portal = useSelector((state) => state.clientManagement.client_portal);
  const navigate = useNavigate();
  const [selectedIndex, setSelectedIndex] = useState(-1);
  const [open, setOpen] = useState(false);
  const anchorRef = useRef(null);
  const handleLogout = async () => {
    dispatch(logout());
  };

  const handleClose = (event) => {
    if (anchorRef.current && anchorRef.current.contains(event.target)) {
      return;
    }
    setOpen(false);
  };

  const handleListItemClick = (event, index, route = '') => {
    setSelectedIndex(index);
    handleClose(event);

    if (route && route !== '') {
      navigate(route);
    }
  };
  const handleToggle = () => {
    setOpen((prevOpen) => !prevOpen);
  };

  const prevOpen = useRef(open);
  useEffect(() => {
    if (prevOpen.current === true && open === false) {
      anchorRef.current.focus();
    }

    prevOpen.current = open;
  }, [open]);

  return (
    <Box sx={{ display: 'flex', alignItems: 'center' }}>
      {/* {!portal && (
        <>
          <Box sx={{ display: { xs: 'none', sm: 'block' }, textAlign: 'end', mr: 2 }}>
            <Typography variant="h3">${(+0).toLocaleString()}</Typography>
            <Typography variant="body2" sx={{ color: theme.palette.text.secondary }}>
              Net Assets
            </Typography>
          </Box>
          <hr
            style={{
              height: 40
            }}
          />
        </>
      )} */}
      <Chip
        sx={{
          height: '48px',
          alignItems: 'center',
          borderRadius: '50px',
          transition: 'all .2s ease-in-out',
          border: 'none',
          '&[aria-controls="menu-list-grow"], &:hover': {
            backgroundColor: 'transparent !important'
          },
          '& .MuiChip-label': {
            lineHeight: 0,
            display: {
              xs: 'none',
              md: 'block'
            }
          }
        }}
        icon={
          <Avatar
            sx={{
              ...theme.typography.mediumAvatar,
              width: 'unset',
              height: 'unset',
              margin: { xs: '0 18px !important', md: '0 0 0 8px !important' },
              cursor: 'pointer',
              p: 1,
              background: '#043E3E26',
              border: '1px solid #043E3E'
            }}
            ref={anchorRef}
            aria-controls={open ? 'menu-list-grow' : undefined}
            aria-haspopup="true"
            color="inherit"
          >
            <Person width="20px" height="20px" />
          </Avatar>
        }
        label={
          <Box sx={{ display: { xs: 'none', md: 'flex' }, alignItems: 'center' }}>
            <Typography variant="body2" sx={{ color: 'black', mr: 0.5 }}>
              {auth?.user?.first_name}
            </Typography>
            {open ? (
              <IconCaretUpFilled stroke={1.5} size="16px" color={'#000'} />
            ) : (
              <IconCaretDownFilled stroke={1.5} size="16px" color={'#000'} />
            )}
          </Box>
        }
        variant="outlined"
        ref={anchorRef}
        aria-controls={open ? 'menu-list-grow' : undefined}
        aria-haspopup="true"
        onClick={handleToggle}
        color="primary"
      />
      <Popper
        placement="bottom-end"
        open={open}
        anchorEl={anchorRef.current}
        role={undefined}
        transition
        disablePortal
        popperOptions={{
          modifiers: [
            {
              name: 'offset',
              options: {
                offset: [0, 14]
              }
            }
          ]
        }}
      >
        {({ TransitionProps }) => (
          <Transitions in={open} {...TransitionProps}>
            <Paper>
              <ClickAwayListener onClickAway={handleClose}>
                <MainCard border={false} elevation={16} content={false} boxShadow shadow={theme.shadows[16]}>
                  <PerfectScrollbar
                    options={{ suppressScrollX: true, suppressScrollY: true }}
                    style={{ height: '100%', maxHeight: 'calc(100vh - 250px)', overflowX: 'hidden' }}
                  >
                    <Box sx={{ py: 2 }}>
                      <List
                        component="nav"
                        sx={{
                          width: '100%',
                          maxWidth: 350,
                          minWidth: 300,
                          backgroundColor: theme.palette.background.paper,
                          borderRadius: '10px',
                          p: 0,
                          '& p': { fontFamily: 'Be Vietnam Pro', mx: 2 },
                          [theme.breakpoints.down('md')]: {
                            minWidth: '100%'
                          }
                        }}
                      >
                        {!portal && (
                          <ListItemButton selected={selectedIndex === 1} onClick={(event) => handleListItemClick(event, 1, '/my_account')}>
                            <ListItemText>
                              <Typography>My Account</Typography>
                            </ListItemText>
                          </ListItemButton>
                        )}
                        {/* <ListItemButton selected={selectedIndex === 1} onClick={(event) => handleListItemClick(event, 1, '/my_account')}>
                          <ListItemText>
                            <Typography>My Account</Typography>
                          </ListItemText>
                        </ListItemButton> */}
                        {/* <ListItemButton
                          disabled
                          sx={{ borderRadius: customization.borderRadius }}
                          selected={selectedIndex === 2}
                          // onClick={(event) => handleListItemClick(event, 2, '/settings')}
                        >
                          <ListItemText>
                            <Typography>Settings</Typography>
                          </ListItemText>
                        </ListItemButton> */}
                        {/* <ListItemButton
                          disabled
                          sx={{ borderRadius: customization.borderRadius }}
                          // selected={selectedIndex === 3}
                          // onClick={(event) => handleListItemClick(event, 3, '/members')}
                        >
                          <ListItemText>
                            <Typography>Members</Typography>
                          </ListItemText>
                        </ListItemButton> */}
                        {/* <ListItemButton
                          disabled
                          sx={{ borderRadius: customization.borderRadius }}
                          // selected={selectedIndex === 4}
                          // onClick={(event) => handleListItemClick(event, 4, '/tenants')}
                        >
                          <ListItemText>
                            <Typography>Tenants</Typography>
                          </ListItemText>
                        </ListItemButton> */}
                        <Divider sx={{ m: 0, mx: 2 }} />
                        <ListItemButton
                          // selected={selectedIndex === 5}
                          onClick={handleLogout}
                        >
                          <ListItemText>
                            <Typography>Logout</Typography>
                          </ListItemText>
                        </ListItemButton>
                      </List>
                    </Box>
                  </PerfectScrollbar>
                </MainCard>
              </ClickAwayListener>
            </Paper>
          </Transitions>
        )}
      </Popper>
    </Box>
  );
};

export default ProfileSection;
