import React from 'react';
import { Box, Typography } from '@mui/material';
// import ExitToAppIcon from '@mui/icons-material/ExitToApp';
import BasicModal from 'ui-component/modal';
import { logout } from 'store/Authentication/action';
import { useDispatch, useSelector } from 'react-redux';
import { RESET_CLIENT_PORTAL } from 'store/clientManagement/constant';
import { ArrowBack, Logout } from '@mui/icons-material';
import { RESET_LIFE_INSURANCE } from 'store/BonsaiExchange/constant';
import { RESET_LONG_TERM_CARE } from 'store/LongtermCare/constant';
import { useNavigate } from 'react-router';
import { RESET_FINANCIAL_PLAN } from 'store/financialTool/constant';

const LogoutButton = () => {
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const [open, setOpen] = React.useState(false);
  const portal = useSelector((state) => state.clientManagement.client_portal);
  const handleOpen = () => setOpen(true);
  const handleClose = () => setOpen(false);
  const handleLogout = () => {
    dispatch(logout());
  };

  const goBack = () => {
    dispatch({
      type: RESET_LIFE_INSURANCE,
      payload: {}
    });
    dispatch({
      type: RESET_LONG_TERM_CARE,
      payload: {}
    });
    dispatch({
      type: RESET_CLIENT_PORTAL,
      payload: {}
    });
    dispatch({
      type: RESET_FINANCIAL_PLAN,
      payload: ''
    });
    navigate('/bonsai_exchange');
  };

  return (
    <>
      <Box
        display="flex"
        alignItems="center"
        justifyContent={portal ? 'flex-start' : 'space-between'}
        onClick={!portal ? handleOpen : goBack}
        sx={{
          cursor: 'pointer',
          background: 'white',
          padding: '16px 20px',
          borderTop: '1px solid #E6E6E6',
          minHeight: '63px',
          maxHeight: '63px'
        }}
      >
        {portal && <ArrowBack sx={{ height: '30px', width: '30px', mr: 1 }} />}
        <Typography variant="h5" sx={{ marginRight: 'auto' }}>
          {!portal ? 'Logout' : 'Back To Dashboard'}
        </Typography>
        {!portal && <Logout sx={{ height: '30px', width: '30px' }} />}
        {/* {!portal ? <Logout /> : <ExitToAppIcon />} */}
      </Box>
      <BasicModal isLogout={true} open={open} setOpen={setOpen} modalClose={handleClose} handleLogout={handleLogout} width={'30%'} />
    </>
  );
};

export default LogoutButton;
