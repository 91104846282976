// material-ui
import { Grid, Typography, Button, Box } from '@mui/material';

// assets
import userLogo from 'assets/images/infographic.png';
import loginBg from 'assets/images/login_bg.png';
import { useSelector } from 'react-redux';
import { Link } from 'react-router-dom';

// ================================|| AUTH3 - LOGIN ||================================ //

const ThankYou = ({ title }) => {
  const tenant = useSelector((state) => state.auth.tenant);
  return (
    <Box
      sx={{
        backgroundImage: `url(${loginBg})`,
        backgroundRepeat: 'no-repeat',
        backgroundPosition: 'top right'
      }}
    >
      <Grid
        container
        mx={'auto'}
        sx={{
          maxWidth: { xs: 400, sm: 700, md: 900, lg: 1200, xl: 1400 }
        }}
      >
        <Grid
          item
          xs={12}
          md={5}
          sx={{
            display: 'flex',
            alignItems: 'center',
            justifyContent: 'center'
          }}
        >
          <img src={userLogo} alt="" style={{ width: '100%' }} />
        </Grid>
        <Grid item xs={12} md={7} sx={{ textAlign: 'center' }}>
          <Grid
            container
            direction="column"
            justifyContent="center"
            alignItems="center"
            sx={{
              py: 5,
              minHeight: { md: '100vh' },
              '& img': {
                width: {
                  xs: 160,
                  sm: 180,
                  md: 210,
                  lg: 250,
                  xl: 300
                }
              }
            }} // Adjust the height as needed
          >
            <Typography variant="h2">WELCOME TO THE</Typography>
            <Typography
              variant="h1"
              sx={{
                fontWeight: 600,
                fontSize: { xs: '1.75rem', sm: '2rem', lg: '3rem', xl: '4.5rem' },
                fontFamily: 'Tungsten',
                mt: 3,
                mb: 2,
                textTransform: 'uppercase'
              }}
            >
              {tenant?.enterprise_name ?? 'BONSAI INSTITUTE'}
            </Typography>
            <Typography variant="h1">{title}</Typography>
            <Button
              variant="contained"
              size="large"
              sx={{
                mt: 2,
                padding: { lg: '8px 20px', xl: '18px 20px' },
                width: '100%',
                maxWidth: { xs: '280px', sm: '350px', lg: '550px' },
                mx: 'auto',
                // background: '#A7D36E73',
                // color: 'rgba(55, 55, 55, 1)',
                boxShadow: 'none',
                // borderRadius: '30px !important',
                height: 'auto',
                fontSize: { xs: '12px', md: '14px', lg: '18px', xl: '22px' },
                '& svg': {
                  marginRight: '20px',
                  maxWidth: {
                    xs: '20px',
                    lg: '35px'
                  }
                }
              }}
              component={Link}
              to="/login"
            >
              {/* <Logo /> */}
              Back to Login
            </Button>
          </Grid>
        </Grid>
      </Grid>
    </Box>
  );
};

export default ThankYou;
