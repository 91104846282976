// action - state management
import { UPDATE_ACCOUNT, GET_LIFE_INSURANCE, RESET_LIFE_INSURANCE } from './constant';

export const initialState = {
  update_account: '',
  get_lifeinsurance: ''
};

// ==============================|| CUSTOMIZATION REDUCER ||============================== //

const bonsaiReducer = (state = initialState, action) => {
  switch (action.type) {
    case UPDATE_ACCOUNT:
      return {
        ...state,
        update_account: action.payload
      };
    case GET_LIFE_INSURANCE:
      return {
        ...state,
        get_lifeinsurance: action.payload
      };
    case RESET_LIFE_INSURANCE:
      return {
        ...state,
        get_lifeinsurance: initialState.get_lifeinsurance
      };
    default:
      return state;
  }
};

export default bonsaiReducer;
