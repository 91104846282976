// action - state management

import {
  DELETE_FINANCIAL_PLAN_DATA,
  GET_CATEGORIES,
  GET_FINANCIAL_PLAN,
  SET_FINANCIAL_PLAN,
  SET_SELECTED_USER,
  GET_GOALS,
  RESET_FINANCIAL_PLAN,
  GET_CASHFLOW,
  GET_PFS_REPORT,
  GET_PORTFOLIO_CURRENT_DATA,
  GET_PLAN,
  GET_OPTIMIZE_DATA
} from './constant';

export const initialState = {
  selectedUser: null,
  pfsReport: null,
  financialPlanData: null,
  goals: null,
  categories: null,
  cashflow: null,
  portfolioCurrentData: null,
  plan: null,
  optimizeData: null,
  financialPlanTotal: null,
  currentTerminalWealth: null,
  dropdowns: {
    WithdrawalLocations: [],
    BrokerageAccounts: [],
    BrokerageAndSavingsAccounts: [],
    AssociatedAccounts: [],
    PaymentAccounts: [],
    Collaterals: [],
    FundingSources: [],
    LinkedProperties: [],
    InsuranceProviders: [],
    LongTermCares: [],
    LinkToLifeRisks: []
  }
};

const subCategoryMap = new Map();
const appendOptions = (options, optionData, _userId) => {
  const { is_active, ...option } = optionData;
  const index = options.findIndex((item) => item.value === option.value);
  if (is_active) {
    if (index === -1) {
      options.push(option);
    } else {
      options[index] = option;
    }
  } else if (index > -1) {
    options.splice(index, 1);
  }
  return options;
};

// Util Functions
let traversedData, option, subCategoryKey, subCategoryName, isNebo, sum;

function traverseData({ financialPlanTotal, dropdowns, currentTerminalWealth }, userId, categoryId, subCategory, operation) {
  const { id, sub_category_id, section_name, is_active } = subCategory;
  option = { label: section_name, value: id, is_active: is_active === 1 ? true : false };
  subCategoryKey = `${categoryId}.${sub_category_id}`;
  const subCategoryData = subCategoryMap.get(subCategoryKey);
  subCategoryName = subCategoryData.name;
  isNebo = subCategoryData.nebo;
  sum = 0;

  if (subCategoryData.totalFields?.length) {
    const totalSum = subCategoryData.totalFields.reduce((acc, field) => acc + (subCategory.data[field] ?? 0), 0);
    const operationSum = subCategoryData.totalFields.reduce((acc, field) => acc + (operation?.[field] ?? 0), 0);

    if (is_active === 1) {
      sum = totalSum;
      if (operation?.is_active === 1) {
        sum -= operationSum;
      }
    } else {
      if (operation?.is_active === 1 || (operation === 'delete' && subCategory?.data?.is_active === 1)) {
        sum -= totalSum;
      }
    }
  }

  // Dropdown Population
  switch (subCategoryName) {
    case 'FinancialAssets.BrokerageAccount':
      dropdowns.BrokerageAccounts = appendOptions(dropdowns.BrokerageAccounts, option, userId);
      dropdowns.AssociatedAccounts = appendOptions(dropdowns.AssociatedAccounts, option, userId);
      dropdowns.FundingSources = appendOptions(dropdowns.FundingSources, option, userId);
      dropdowns.BrokerageAndSavingsAccounts = appendOptions(dropdowns.BrokerageAndSavingsAccounts, option, userId);
      break;
    case 'FinancialAssets.SavingsAccount':
      dropdowns.BrokerageAndSavingsAccounts = appendOptions(dropdowns.BrokerageAndSavingsAccounts, option, userId);
      dropdowns.AssociatedAccounts = appendOptions(dropdowns.AssociatedAccounts, option, userId);
      dropdowns.PaymentAccounts = appendOptions(dropdowns.PaymentAccounts, option, userId);
      dropdowns.FundingSources = appendOptions(dropdowns.FundingSources, option, userId);
      break;
    case 'FinancialAssets.CheckingAccount':
    case 'FinancialAssets.MoneyMarketAccount':
      dropdowns.AssociatedAccounts = appendOptions(dropdowns.AssociatedAccounts, option, userId);
      dropdowns.PaymentAccounts = appendOptions(dropdowns.PaymentAccounts, option, userId);
      dropdowns.FundingSources = appendOptions(dropdowns.FundingSources, option, userId);
      break;
    case 'DefinedAssets.CorporatePension':
    case 'DefinedAssets.SocialSecurity':
      dropdowns.AssociatedAccounts = appendOptions(dropdowns.AssociatedAccounts, option, userId);
      dropdowns.FundingSources = appendOptions(dropdowns.FundingSources, option, userId);
      break;
    case 'CurrentExpenses.Loan':
    case 'CurrentExpenses.LineOfCredit':
      dropdowns.AssociatedAccounts = appendOptions(dropdowns.AssociatedAccounts, option, userId);
      break;
    case 'OutsideAssets.RealProperty':
      dropdowns.LinkedProperties = appendOptions(dropdowns.LinkedProperties, option, userId);
      break;
    case 'FinancialAssets.Cash':
    case 'FinancialAssets.Annuity':
    case 'DefinedAssets.Annuity':
    case 'Inheritance.TrustDistribution':
      dropdowns.FundingSources = appendOptions(dropdowns.FundingSources, option, userId);
      break;
    case 'Healthcare.InsuranceProvider':
      dropdowns.InsuranceProviders = appendOptions(dropdowns.InsuranceProviders, option, userId);
      break;
    case 'Healthcare.LongTermCare':
      dropdowns.LongTermCares = appendOptions(dropdowns.LongTermCares, option, userId);
      break;
    case 'LifeEvents.Death':
      dropdowns.LinkToLifeRisks = appendOptions(dropdowns.LinkToLifeRisks, option, userId);
      break;
    case 'TerminalWealth.TerminalWealthAmount':
      currentTerminalWealth += sum;
      break;
  }

  if (isNebo) {
    // For Assets only
    dropdowns.Collaterals = appendOptions(dropdowns.Collaterals, option, userId);
  }

  if ((is_active === 1 || operation?.is_active === 1 || operation === 'delete') && !isNaN(sum)) {
    financialPlanTotal += sum;
  }
  // For All
  dropdowns.WithdrawalLocations = appendOptions(dropdowns.WithdrawalLocations, option, userId);
  return { dropdowns, financialPlanTotal, currentTerminalWealth };
}

// ==============================|| CUSTOMIZATION REDUCER ||============================== //

const financialPlanReducer = (state = initialState, action) => {
  switch (action.type) {
    case GET_CATEGORIES:
      return {
        ...state,
        categories: action.payload
      };
    case GET_PLAN:
      return {
        ...state,
        plan: action.payload
      };
    case GET_OPTIMIZE_DATA:
      return {
        ...state,
        optimizeData: action.payload
      };
    case GET_GOALS:
      return {
        ...state,
        goals:
          Array.isArray(action.payload) && state.goals
            ? state.goals.map((goal) => {
                const updatedGoal = action.payload?.find((newGoal) => newGoal.user_id === goal.user_id);
                return updatedGoal ? { ...goal, ...updatedGoal } : goal;
              })
            : action.payload
      };
    case SET_FINANCIAL_PLAN:
      return {
        ...state,
        financialPlanData: action.payload
      };
    case GET_PORTFOLIO_CURRENT_DATA:
      return {
        ...state,
        portfolioCurrentData: action.payload
      };
    case GET_CASHFLOW:
      return {
        ...state,
        cashflow: action.payload
      };
    case GET_PFS_REPORT:
      return {
        ...state,
        pfsReport: action.payload
      };
    case GET_FINANCIAL_PLAN: {
      const { data, singleUser, update } = action.payload;
      const financialPlanData = {
        ...state.financialPlanData
      };
      let dropdowns = structuredClone(initialState.dropdowns);
      let financialPlanTotal = {};
      let currentTerminalWealth = 0;
      Object.entries(state.categories ?? {}).forEach(([categoryName, { _id: categoryId, ...subCategories }]) => {
        financialPlanTotal[categoryId] = 0;
        Object.entries(subCategories).forEach(([subCategoryName, { _id: subCategoryId, nebo, fields }]) => {
          !subCategoryMap.has(`${categoryId}.${subCategoryId}`) &&
            subCategoryMap.set(`${categoryId}.${subCategoryId}`, {
              name: `${categoryName}.${subCategoryName}`,
              nebo,
              totalFields: !fields ? [] : Object.entries(fields).flatMap(([field, { total }]) => (total ? [field] : []))
            });
        });
      });

      // Update Case
      if (update) {
        dropdowns = state.dropdowns;
        financialPlanTotal = state.financialPlanTotal;
        currentTerminalWealth = state.currentTerminalWealth;
        data.forEach((item) => {
          const categoryData = financialPlanData[`${item.category_id}`] ?? {};
          const userData = categoryData[`${item.user_id}`] ?? [];
          const sectionIndex = userData.findIndex((section) => section.id === item.id);
          const sectionItem = {
            id: item.id,
            sub_category_id: item.sub_category_id,
            nebo: item.nebo,
            is_active: item.is_active,
            section_name: item.section_name,
            ...item.data,
            checked: false
          };

          traversedData = traverseData(
            { financialPlanTotal: financialPlanTotal[`${item.category_id}`], dropdowns, currentTerminalWealth },
            item.user_id,
            item.category_id,
            item,
            userData[sectionIndex]
          );
          dropdowns = traversedData.dropdowns;
          financialPlanTotal[`${item.category_id}`] = traversedData.financialPlanTotal;
          currentTerminalWealth = traversedData.currentTerminalWealth;

          if (sectionIndex !== -1) {
            userData[sectionIndex] = sectionItem;
          } else {
            userData.push(sectionItem);
          }

          categoryData[`${item.user_id}`] = userData;
          financialPlanData[`${item.category_id}`] = categoryData;
        });
      } else {
        const usersData = singleUser ? [data] : data;
        !update &&
          usersData.forEach((user) => {
            Object.entries(user?.financial_plan ?? {}).forEach(([categoryId, subCategories]) => {
              if (!financialPlanData[categoryId]) {
                financialPlanData[categoryId] = {};
              }

              if (!financialPlanTotal[categoryId]) {
                financialPlanTotal[categoryId] = 0;
              }

              financialPlanData[categoryId][user.user_id] = subCategories.map((subCategory) => {
                const { id, sub_category_id, nebo, is_active, section_name, data } = subCategory;
                traversedData = traverseData(
                  { financialPlanTotal: financialPlanTotal[categoryId], dropdowns, currentTerminalWealth },
                  user.user_id,
                  categoryId,
                  subCategory
                );
                dropdowns = traversedData.dropdowns;
                financialPlanTotal[categoryId] = traversedData.financialPlanTotal;
                currentTerminalWealth = traversedData.currentTerminalWealth;
                return {
                  id,
                  sub_category_id,
                  nebo,
                  is_active,
                  section_name,
                  ...data,
                  checked: false
                };
              });
            });
          });
      }

      return {
        ...state,
        financialPlanData,
        financialPlanTotal,
        currentTerminalWealth,
        dropdowns
      };
    }
    case DELETE_FINANCIAL_PLAN_DATA: {
      const { categoryId, userId, subCategoryId, financialPlanDataId, data } = action.payload;
      let dropdowns = structuredClone(state.dropdowns);
      let financialPlanTotal = structuredClone(state.financialPlanTotal);
      let currentTerminalWealth = structuredClone(state.currentTerminalWealth);
      traversedData = traverseData(
        { financialPlanTotal: financialPlanTotal[categoryId], currentTerminalWealth, dropdowns },
        userId,
        categoryId,
        {
          id: financialPlanDataId,
          sub_category_id: subCategoryId,
          is_active: 0,
          data
        },
        'delete'
      );
      return {
        ...state,
        financialPlanData: {
          ...state.financialPlanData,
          [categoryId]: {
            ...state.financialPlanData[categoryId],
            [userId]: state.financialPlanData?.[categoryId]?.[userId]?.filter((subCategory) => subCategory.id !== financialPlanDataId)
          }
        },
        dropdowns: traversedData.dropdowns,
        financialPlanTotal: {
          [categoryId]: traversedData.financialPlanTotal
        },
        currentTerminalWealth: traversedData.currentTerminalWealth
      };
    }
    case SET_SELECTED_USER:
      return {
        ...state,
        selectedUser: action.payload
      };
    case RESET_FINANCIAL_PLAN: {
      localStorage.removeItem('selectedUser');
      return { ...initialState };
    }
    default:
      return state;
  }
};

export default financialPlanReducer;
