import axios from 'utils/axios';
import { toast } from 'react-toastify';
import { LOGIN } from './constant';
import { getOptions } from 'store/Options/action';
import { GET_TENANT } from './constant';
import config from '../../config';

export const Authentication = (payload) => async (dispatch) => {
  const url = 'authenticate';
  try {
    const response = await axios.post(url, { id: payload });
    const Token = response.data.data.access_token;
    const Role = response.data.data.user.user_role;
    const Status = response.data.data.user.status != 0;
    const userId = response.data.data.user.id;
    const Subscription = response.data.data.user.subscription;
    dispatch({
      type: LOGIN,
      payload: response.data.data
    });
    localStorage.setItem('Token', Token);
    localStorage.setItem('Role', Role);
    localStorage.setItem('user_id', userId);
    localStorage.setItem('Status', Status);
    localStorage.setItem('Subscription', Subscription);
    toast.success(response.data.message);
    await Promise.resolve(dispatch(getOptions()));
    setTimeout(() => {
      window.location.href = '/';
    }, 700);
  } catch (err) {
    toast.error(err?.response?.data?.message ?? 'Something went wrong!');
  }
};

export const ActivateUser = (payload, setIsSignedUp, setTitle) => async () => {
  const url = `activate`;
  try {
    const response = await axios.post(url, { id: payload });
    window.location.href = response.data.sso_url;
  } catch (err) {
    console.log(err);
    if (err?.response?.status === 404) {
      window.location.href = '/login';
    } else if (err?.response?.status === 409) {
      setIsSignedUp(true);
      setTitle(err?.response?.data?.message);
    } else {
      toast.error(err?.response?.data?.message ?? 'Something went wrong!');
    }
  }
};

export const isLogin = () => {
  const token = localStorage.getItem('Token');
  if (token) {
    return true;
  }
  return false;
};

export const signup = (payload) => async () => {
  const url = 'register';
  const data = {
    first_name: payload.firstName,
    last_name: payload.lastName,
    email: payload.email,
    dob: payload.dob,
    resident_state: payload.residentState,
    work_phone: payload.workPhone,
    mobile_phone: payload.mobilePhone,
    firm_name: payload.firmName,
    firm_type_id: payload.firmType,
    finra_crd: payload.finraCrd,
    partner_id: payload.referralCode,
    insurance_npn: payload.insuranceNpn
  };
  payload.licenseOptions?.forEach(({ id, isSelected }) => {
    data[id] = isSelected ? isSelected : null;
  });
  try {
    payload.setLoading(true);
    const response = await axios.post(url, data);
    payload.setIsSignedUp(true);
    payload.setTitle(response.data.message);
    // toast.success(response.data.message);
    payload.setLoading(false);
  } catch (err) {
    toast.error(err?.response?.data?.message ?? 'Something went wrong!');
    payload.setLoading(false);
  }
};

export const emailVerification = (payload, setIsSignedUp, setTitle, setLoading) => async () => {
  const url = 'check-email';
  const data = {
    email: payload.email
  };
  try {
    setLoading(true);
    const response = await axios.post(url, data);
    // console.log(response, 'response');
    toast.success(response.data.message);
    if (response.data.url?.is_active === 0) {
      setIsSignedUp(true);
      setTitle(response.data.message);
    }
    setLoading(false);
    window.location.href = response?.data?.url?.sso_url;
  } catch (err) {
    setLoading(false);
    if (err.response.status === 404) {
      window.location.href = '/signup';
    } else if (err.response.status === 409) {
      localStorage.setItem('ResendEmail', payload.email);
      window.location.href = '/resend-email';
    } else {
      toast.error(err?.response?.data?.message ?? 'Something went wrong!');
    }
  }
};

export const resendInvitation = (payload, setLoading) => async () => {
  const url = 'resend-invitation';
  const data = {
    email: payload
  };
  try {
    setLoading(true);
    const response = await axios.post(url, data);
    setLoading(false);
    toast.success(response.data.message);
  } catch (err) {
    setLoading(false);
    toast.error(err?.response?.data?.message ?? 'Something went wrong!');
  }
};

export const logout = () => async () => {
  try {
    const response = await axios.post('logout');
    localStorage.clear();
    console.log(response);
    window.location.href = config.EXTERNAL_URLS.LOGOUT_URL;
  } catch (err) {
    console.log(err, 'err');
    toast.error(err?.response?.data?.message ?? 'Something went wrong!');
  }
};

export const getTenant = (setLoading) => async (dispatch) => {
  try {
    setLoading && setLoading(true);
    const subdomain = window.location.hostname?.split('.')?.[0];
    const response = await axios.get('get-tenant-data', {
      params: {
        url: subdomain
      }
    });
    const tenantData = response.data.tenant;
    dispatch({
      type: GET_TENANT,
      payload: tenantData
    });
  } catch (err) {
    toast.error(err?.response?.data?.message ?? 'Something went wrong!');
  }
  setLoading && setLoading(false);
};
