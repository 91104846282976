export const GET_LIFE_INSURANCE = 'GET_LIFE_INSURANCE';
export const GET_LONGTERM_CARE_AND_MEDICARE = 'GET_LONGTERM_CARE_AND_MEDICARE';
export const GET_PERMANENT_LIFE_INSURANCE = 'GET_PERMANENT_LIFE_INSURANCE';
export const APPROVE_REQUEST = 'APPROVE_REQUEST';
export const EDIT_REQUEST = 'EDIT_REQUEST';
export const RE_SUBMIT = 'RE_SUBMIT';
export const VIEW_LONG_TERM_CARE_AND_MEDICARE = 'VIEW_LONG_TERM_CARE_AND_MEDICARE';
export const VIEW_LIFE_INSURANCE = 'VIEW_LIFE_INSURANCE';
export const VIEW_PERMANENT_LIFE_INSURANCE = 'VIEW_PERMANENT_LIFE_INSURANCE';
export const ADD_PLI_NOTE = 'ADD_PLI_NOTE';
export const ADD_LTC_NOTE = 'ADD_LTC_NOTE';
export const ADD_LI_NOTE = 'ADD_LI_NOTE';
export const UPDATE_LTC_NOTE = 'UPDATE_LTC_NOTE';
export const UPDATE_PLI_NOTE = 'UPDATE_PLI_NOTE';
export const UPDATE_LI_NOTE = 'UPDATE_LI_NOTE';
export const DELETE_LTC_NOTE = 'DELETE_LTC_NOTE';
export const DELETE_PLI_NOTE = 'DELETE_PLI_NOTE';
export const DELETE_LI_NOTE = 'DELETE_LI_NOTE';
