import { lazy } from 'react';

// project imports
import Loadable from 'ui-component/Loadable';
import MinimalLayout from 'layout/MinimalLayout';
import { PublicRoute } from './protectedRoute';
import { isLogin } from 'store/Authentication/action';
import AuthSignup from 'views/pages/authentication/Signup';
// login option 3 routing
const AuthLogin = Loadable(lazy(() => import('views/pages/authentication/authentication3/Login')));
const ActivateUser = Loadable(lazy(() => import('views/pages/authentication/authentication3/activateUser')));
// const AuthSignup = Loadable(lazy(() => import('views/pages/authentication/Signup')));
const ResendInvitation = Loadable(lazy(() => import('views/pages/authentication/authentication3/resendInvitation')));
const InactiveTenant = Loadable(lazy(() => import('views/pages/ErrorPage/inactiveTenant')));
const EmailVerification = Loadable(lazy(() => import('views/pages/authentication/authentication3/emailVerification')));

// ==============================|| AUTHENTICATION ROUTING ||============================== //

const AuthenticationRoutes = {
  path: '/',
  element: <MinimalLayout />,
  children: [
    {
      path: '/login',
      element: (
        <PublicRoute isAuthenticated={isLogin()}>
          <AuthLogin />
        </PublicRoute>
      )
    },
    {
      path: '/resend-email',
      element: (
        <PublicRoute isAuthenticated={isLogin()}>
          <ResendInvitation />
        </PublicRoute>
      )
    },
    {
      path: '/activate',
      element: (
        <PublicRoute isAuthenticated={isLogin()}>
          <ActivateUser />
        </PublicRoute>
      )
    },
    {
      path: '/signup',
      element: (
        <PublicRoute isAuthenticated={isLogin()}>
          <AuthSignup />
        </PublicRoute>
      )
    },
    {
      path: '/get-email',
      element: (
        <PublicRoute isAuthenticated={isLogin()}>
          <EmailVerification />
        </PublicRoute>
      )
    },
    {
      path: '/unavailable',
      element: (
        <PublicRoute isAuthenticated={isLogin()}>
          <InactiveTenant />
        </PublicRoute>
      )
    }
  ]
};

export default AuthenticationRoutes;
