import { useCallback, useMemo } from 'react';
import TextField from '@mui/material/TextField';
import withController from './withController';
import { transformLabel } from './utils';

const RHFTextField = ({
  name,
  invalid,
  additionalError,
  onChange: fieldChange,
  beforeValueChange,
  afterValueChange,
  children,
  ...props
}) => {
  const label = useMemo(() => props.label ?? transformLabel(name) ?? '', [props.label, name]);
  const placeholder = useMemo(() => {
    if (props.placeholder === true) {
      return `Enter ${label}`;
    }
    return props.placeholder;
  }, [props.placeholder, label]);

  const onChange = useCallback(
    (e) => {
      if (beforeValueChange) {
        e.target.value = beforeValueChange(e.target.value);
      }
      fieldChange(e);
      afterValueChange && afterValueChange(e.target.value);
    },
    [beforeValueChange, fieldChange, afterValueChange]
  );

  const error = useMemo(() => Boolean(additionalError) || invalid, [additionalError, invalid]);
  const helperText = useMemo(() => additionalError ?? props.error?.message, [additionalError, props.error]);

  const commonProps = {
    ...props,
    onChange: onChange,
    label: label,
    placeholder: placeholder,
    error,
    helperText,
    fullWidth: true,
    InputLabelProps: {
      style: { overflow: 'hidden !important' }
    }
  };

  if (!children) {
    return <TextField {...commonProps} />;
  }
  return <TextField {...commonProps}>{children}</TextField>;
};

export default withController(RHFTextField);
