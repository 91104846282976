import React from 'react';
import { Controller, useFormContext } from 'react-hook-form';

const withController =
  (Component) =>
  ({ name, rules, defaultValue, ...props }) => {
    const { control } = useFormContext();
    return (
      <Controller
        control={control}
        name={name}
        rules={rules}
        defaultValue={defaultValue}
        render={({ field, fieldState }) => <Component id={field.name} {...field} {...fieldState} {...props} />}
      />
    );
  };

export default withController;
